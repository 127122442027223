<script lang="tsx">
import type { SlotsType } from 'vue'
import { CoreUiCheckbox } from '#components'
import type { Getter } from '@core/types/utility'
import type { ComponentOverrideOptions } from '@core/types/components'

export type CoreUiSelectorProps<T, Colors extends string, Sizes extends string> = {
    values: T[],
    /**
     * The size of the selector input.
     */
    size?: Sizes
    /**
     * Whether to allow multiple selections or not.
     * Converts the inputs from radios to checkboxes.
     */
    multiple?: boolean
    /**
     * Whether the selector is disabled or not.
     */
    disabled?: boolean
    /**
     * Whether the selector is loading or not.
     */
    loading?: boolean
    color?: Colors
    modelValue?: T | T[] | null | string[]
    /**
     * The aria label for the selector.
     * If not set, the translation key will be used with
     * a generic default value or the value of the `ariaLabelKey` prop.
     */
    ariaLabel?: string
    /**
     * The translation key for the aria label.
     * If the `ariaLabel` prop is set, this will be ignored.
     */
    ariaLabelKey?: string
    valueGetter?: Getter<T> | { fn: (props: Omit<CoreUiSelectorProps<T, Colors, Sizes>, 'valueGetter'>) => Getter<T> }
    labelGetter?: Getter<T> | { fn: (props: Omit<CoreUiSelectorProps<T, Colors, Sizes>, 'labelGetter'>) => Getter<T> }
    /**
     * Whether to use the index of the item as the html input value.
     * Can be set to a function to determine whether to use the index as the value or not
     * for the specific item.
     *
     * This should probably be only used in very specific cases & in the core.
     */
    useIndexAsValue?: boolean | ((item: T) => boolean)
    /**
     * Function to determine whether an item is disabled or not.
     */
    isItemDisabled?: (item: T) => boolean
    /**
     * Whether to render disabled items or not.
     * When set to `true`, disabled items will not be rendered.
     * @default false
     */
    hideDisabled?: boolean
    /**
     * Whether to only display the selector without any interactivity.
     * Setting this prop to `true` will not render the html inputs & the fieldset but rather
     * use regular divs.
     */
    displayOnly?: boolean
}

export type CoreUiSelectorSlots<T> = {
    checkbox: { item: T, index: number, isSelected: boolean }
}

export type CoreUiSelectorOptions = {}

export function defineComponentCoreUiSelector<T, Colors extends string, Sizes extends string>(_options?: ComponentOverrideOptions<CoreUiSelectorOptions, CoreUiSelectorProps<T, Colors, Sizes>, CoreUiSelectorSlots<T>>) {
    return defineComponent(
        (props: CoreUiSelectorProps<T, Colors, Sizes>, ctx) => {
            const useIndexAsValue = computed(() => {
                const item = props.values[0]
                if (!item) return false
                if (typeof props.useIndexAsValue === 'function') return props.useIndexAsValue(item)
                return props.useIndexAsValue
            })
            const valueGetter = computed(() => props.valueGetter)
            const labelGetter = computed(() => props.labelGetter || 'label')

            const { t } = useI18n({ useScope: 'global' })

            function normalizeGetter(getter: CoreUiSelectorProps<T, Colors, Sizes>['valueGetter'] | CoreUiSelectorProps<T, Colors, Sizes>['labelGetter']): Getter<T> {
                if (getter && typeof getter === 'object' && 'fn' in getter) {
                    return getter.fn(props as any) as Getter<T>
                }

                return getter as Getter<T>
            }

            const selectedItemValue = computed({
                get: () => {
                    if (props.multiple) {
                        return props.modelValue ?? []
                    }

                    if (useIndexAsValue.value) {
                        if (!props.modelValue) return null

                        // TODO: find out a better way to compare equality
                        // / maybe use the value getter but only to differentiate the objects when using
                        // / index as value?
                        const modelValue = JSON.stringify(props.modelValue)
                        return props.values.findIndex(value => JSON.stringify(value) === modelValue)
                    }

                    return getValueByGetter(props.modelValue as any, normalizeGetter(valueGetter.value))
                },
                set: (value) => {
                    if (props.multiple) {
                        ctx.emit('update:modelValue', value as any)
                        return
                    }

                    if (value === null) {
                        ctx.emit('update:modelValue', null)
                        ctx.emit('change', null)
                        return
                    }

                    if (value || typeof value === 'number') {
                        const isValueDifferent = useIndexAsValue.value
                            ? props.values[value as number] !== props.modelValue
                            : value !== props.modelValue

                        if (isValueDifferent) ctx.emit('change', useIndexAsValue.value ? props.values[value as number] : value as any)

                        ctx.emit('update:modelValue', useIndexAsValue.value ? props.values[value as number] : value as any)
                    }
                },
            })

            const radioGroup = `selector-${useId()}`

            const isDisabled = computed<boolean>(() => !!(props.disabled || props.loading))

            return () => !props.displayOnly
                // FUNCTIONAL version of the selector
                ? (
                    <fieldset class={['sim-selector', {
                        'sim-selector--loading': props.loading,
                        'sim-selector--disabled': isDisabled.value,
                    }]}
                    >
                        <legend class="visually-hidden">
                            {props.ariaLabel || t(props.ariaLabelKey as string)}
                        </legend>

                        {props.values.map((item, index) => {
                            const isItemDisabled = (props.isItemDisabled ? props.isItemDisabled(item) : false) || isDisabled.value

                            if (props.hideDisabled && isItemDisabled) return null

                            return (
                                <CoreUiCheckbox
                                    inputClass={[
                                        'sim-selector__checkbox',
                                        {
                                            'sim-selector__checkbox--disabled': isItemDisabled,
                                            [`sim-selector__checkbox--${props.size}`]: props.size !== 'md' && props.size !== undefined,
                                            [`sim-selector__checkbox--c-${props.color}`]: !!props.color,
                                        },
                                    ]}
                                    disabled={isItemDisabled}
                                    key={getValueByGetter(item, normalizeGetter(valueGetter.value)) as string ?? index}
                                    v-model={selectedItemValue.value}
                                    value={useIndexAsValue.value ? index : getValueByGetter(item, normalizeGetter(valueGetter.value)) as any}
                                    aria-label={getValueByGetter(item, normalizeGetter(labelGetter.value))}
                                    type={props.multiple ? 'checkbox' : 'radio'}
                                    allow-radio-uncheck
                                    name={radioGroup}
                                >
                                    {{
                                        checkbox: ({ checked }: {
                                            checked: boolean
                                        }) => renderSlot(ctx.slots.checkbox, _options?.slots?.checkbox, {
                                            item: item,
                                            index: index,
                                            isSelected: checked,
                                        }, (
                                            <>
                                                {getValueByGetter(item, normalizeGetter(labelGetter.value))}
                                            </>
                                        )),
                                    }}
                                </CoreUiCheckbox>
                            )
                        })}
                    </fieldset>
                )
                // DISPLAY ONLY version of the selector
                : (
                    <div class={['sim-selector sim-selector--display-only', {
                        'sim-selector--loading': props.loading,
                        'sim-selector--disabled': isDisabled.value,
                    }]}
                    >
                        {props.values.map((item, index) => {
                            const isItemDisabled = (props.isItemDisabled ? props.isItemDisabled(item) : false) || isDisabled.value
                            if (props.hideDisabled && isItemDisabled) return null

                            return (
                                <div
                                    key={getValueByGetter(item, normalizeGetter(valueGetter.value)) as string ?? index}
                                    class={['sim-selector__checkbox', {
                                        'sim-selector__checkbox--disabled': isItemDisabled,
                                        [`sim-selector__checkbox--${props.size}`]: props.size !== 'md',
                                        [`sim-selector__checkbox--c-${props.color}`]: props.color,
                                    }]}
                                >
                                    {
                                        renderSlot(ctx.slots.checkbox, _options?.slots?.checkbox, {
                                            item: item,
                                            index: index,
                                            isSelected: selectedItemValue.value === getValueByGetter(item, normalizeGetter(valueGetter.value)),
                                        }, (
                                            <>
                                                {getValueByGetter(item, normalizeGetter(labelGetter.value))}
                                            </>
                                        ))
                                    }
                                </div>
                            )
                        })}
                    </div>
                )
        },
        {
            props: defineRuntimeProps<CoreUiSelectorProps<T, Colors, Sizes>>({
                values: { type: Array },
                // @ts-ignore
                size: { type: String },
                // @ts-ignore
                color: { type: String },
                multiple: { type: Boolean },
                disabled: { type: Boolean },
                loading: { type: Boolean },
                // @ts-ignore
                modelValue: { type: [String, Array, Object] },
                ariaLabel: { type: String },
                ariaLabelKey: {
                    type: String,
                    default: '_core_simploshop.labels.selector',
                },
                // @ts-ignore
                valueGetter: { type: [Function, String, Number, Symbol, Object] },
                // @ts-ignore
                labelGetter: { type: [Function, String, Number, Symbol, Object] },
                useIndexAsValue: {
                    // @ts-ignore
                    type: [Boolean, Function],
                    default: true,
                },
                // @ts-ignore
                isItemDisabled: { type: Function },
                hideDisabled: { type: Boolean },
                displayOnly: { type: Boolean },
            }, _options),
            slots: Object as SlotsType<CoreUiSelectorSlots<T>>,
            emits: {
                'update:modelValue': (value: CoreUiSelectorProps<T, Colors, Sizes>['modelValue']) => true,
                'change': (value: Required<CoreUiSelectorProps<T, Colors, Sizes>>['modelValue']): any => true,
            },
        }
    )
}

export default defineComponentCoreUiSelector()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiSelector.scss" as *;

</style>
